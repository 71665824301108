<template>
  <div>
    <v-form ref="form" v-model="valid" class="event-form">
      <div class="grey">
        <div class="wide-container">
          <TranslationFormSection :card="false" wide compact>
            <div slot="section-content">
              <v-container>
                <TranslationFormSection :card="false" compact>
                  <div slot="section-content">
                    <v-row>
                      <v-col cols="12" md="6">
                        <label class="event-link">Event ID: </label>
                        {{ CURRENT_EVENT_OBJECT.id }}
                      </v-col>
                      <v-col cols="12" md="6">
                        <label class="event-link">Event Status: </label>
                        {{
                          CURRENT_EVENT_OBJECT.archived
                            ? 'Archived'
                            : CURRENT_EVENT_OBJECT.status
                        }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <label class="event-link">Public Event URL: </label>
                        <v-skeleton-loader
                          v-if="!bitlyLink"
                          class="bitly-skeleton"
                          max-width="300"
                          type="text"
                        ></v-skeleton-loader>
                        <a :href="bitlyLink" target="_blank">{{ bitlyLink }}</a>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        v-if="
                          CURRENT_EVENT_OBJECT.contestType ===
                            'Enter for a Chance to Win'
                        "
                      >
                        <label class="event-link">
                          Public Rules URL:
                        </label>
                        <router-link
                          :to="'/event/rules/' + $route.params.id"
                          target="_blank"
                        >
                          {{
                            urlRoot +
                              '/#/event/rules/' +
                              CURRENT_EVENT_OBJECT.id
                          }}
                        </router-link>
                      </v-col>
                    </v-row>
                  </div>
                </TranslationFormSection>
              </v-container>
            </div>
          </TranslationFormSection>
        </div>
      </div>
      <div class="wide-container">
        <TranslationFormSection :card="false">
          <div slot="section-content">
            <v-container>
              <TranslationFormSection :card="false" compact>
                <span slot="section-header">Translation Fields</span>
                <div slot="section-content">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Event Name:
                        </label>
                      </v-col>
                      <v-col cols="12" md="6" class="event-info">
                        {{ CURRENT_EVENT_OBJECT.name }}
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          ref="name"
                          v-model="translations.event_name"
                          :rules="[
                            nonEmptyRule(
                              'You must supply a translation for Event Name'
                            )
                          ]"
                          hide-details="auto"
                          append-icon="mdi-alert-circle"
                          required
                          :readonly="!!CURRENT_EVENT_OBJECT.archived"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="showSweepstakesTitle">
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Sweepstakes Title:
                        </label>
                      </v-col>
                      <v-col cols="12" md="6" class="event-info">
                        <p>{{ CURRENT_EVENT_OBJECT.sweepstakesTitle }}</p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          ref="sweepstakes"
                          v-model="translations.sweepstakesTitle"
                          :rules="[
                            nonEmptyRule(
                              'You must supply a translation for Sweepstakes Title.'
                            )
                          ]"
                          hide-details="auto"
                          append-icon="mdi-alert-circle"
                          required
                          :readonly="!!CURRENT_EVENT_OBJECT.archived"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Abbreviated Prize Description:
                        </label>
                      </v-col>
                      <v-col cols="12" md="6" class="event-info">
                        <p>
                          {{ CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription }}
                        </p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-textarea
                          ref="abbreviatedPrizeDescription"
                          v-model="translations.abbreviatedPrizeDescription"
                          :rules="[
                            nonEmptyRule(
                              'You must provide an abbreviated prize description.'
                            ),
                            v =>
                              v == null ||
                              v.length <= 100 ||
                              'Abbreviated prize description cannot be greater than 100 characters.'
                          ]"
                          rows="1"
                          required
                          auto-grow
                          hide-details="auto"
                          append-icon="mdi-alert-circle"
                          :readonly="!!CURRENT_EVENT_OBJECT.archived"
                          counter="100"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row v-if="showFullPrizeDescription">
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Full Prize Description:
                        </label>
                      </v-col>
                      <v-col cols="12" md="6 " class="event-info">
                        <p v-html="formattedPrizeDescription" class="ma-0"></p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-textarea
                          ref="fullPrizeDescription"
                          v-model="translations.fullPrizeDescription"
                          :rules="[
                            nonEmptyRule(
                              'You must provide a prize description.'
                            ),
                            v =>
                              v == null ||
                              v.length <= 500 ||
                              'Prize description cannot be greater than 500 characters.'
                          ]"
                          rows="1"
                          auto-grow
                          hide-details="auto"
                          append-icon="mdi-alert-circle"
                          :readonly="!!CURRENT_EVENT_OBJECT.archived"
                          counter="500"
                          required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row v-if="CURRENT_EVENT_OBJECT.abbreviatedLegal">
                      <v-col cols="12" md="12">
                        <label class="event-link">
                          Abbreviated Legal
                        </label>
                      </v-col>
                      <v-col cols="12" md="6" class="event-info">
                        <p
                          v-html="CURRENT_EVENT_OBJECT.abbreviatedLegal"
                          class="ma-0"
                        ></p>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-textarea
                          ref="abbreviatedLegal"
                          v-model="translations.abbreviatedLegal"
                          :rules="[
                            nonEmptyRule(
                              'You must supply a translation for Abbreviated Legal.'
                            )
                          ]"
                          hide-details="auto"
                          rows="1"
                          append-icon="mdi-alert-circle"
                          required
                          auto-grow
                          :readonly="!!CURRENT_EVENT_OBJECT.archived"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </TranslationFormSection>
            </v-container>
          </div>
        </TranslationFormSection>
        <v-container>
          <v-row
            class="justify-center cta-buttons"
            v-show="CURRENT_EVENT_OBJECT.status === 'Translation Pending'"
          >
            <v-col cols="10" md="3" lg="2">
              <v-btn
                elevation="2"
                @click="approveTranslationAction()"
                tile
                block
                x-large
                :loading="submitLoading"
                :disabled="
                  CURRENT_EVENT_OBJECT.status !== 'Translation Pending' ||
                    this.USER_GROUPS.indexOf('SLTranslator') === -1
                "
                color="primary"
              >
                <span class="white--text button-text">
                  Approve Translation
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-form>
    <v-dialog
      v-model="showWarningDialog"
      width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          Event Status Changed
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          This event's status has changed to {{ CURRENT_EVENT_OBJECT.status }}.
          An event can only be Approved when it is in Translation Pending
          status.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="px-6 modal-button-outlined"
            color="primary"
            outlined
            tile
            x-large
            @click="showWarningDialog = false"
          >
            <span class="black--text button-text">
              Close
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showApproveDialog"
      width="500"
      content-class="app-modal-dialog"
    >
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          EVENT TRANSLATED
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          Thank you! The Event Manager will be notified of their event status
          change.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn
            class="px-6 modal-button-outlined"
            color="primary"
            outlined
            tile
            x-large
            @click="
              USER_GROUPS.indexOf('SLTranslator') !== -1
                ? $router.push('/translationdashboard')
                : $router.push('/translationdashboard')
            "
          >
            <span class="black--text button-text">
              Close
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" width="600" content-class="app-modal-dialog">
      <v-card class="pa-8">
        <v-card-title class="justify-center grey lighten-2">
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="mt-2 text-center">
          {{ dialogMessage }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-center pt-8">
          <v-btn @click="dialogAction" text class="px-6 btn__primary">
            {{ dialogActionText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ThemeSwitcher from '@/utility/ThemeSwitcher';
import OptionData from '@/utility/OptionData';
import HelperFunctions from '@/utility/HelperFunctions';
import TranslationFormSection from '@/components/EventForm/TranslationFormSection.vue';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'TranslationSummaryForm',
  components: {
    TranslationFormSection
  },

  mounted() {
    if (this.CURRENT_EVENT_OBJECT.brand == null) {
      this.CURRENT_EVENT_OBJECT.brand = 'T-Mobile';
    }
    if (this.USER_GROUPS.indexOf('Admin') !== -1) {
      this.displayDialog(
        'Admin Notice',
        'An event can only be edited by Translation users.',
        'Close',
        () => (this.dialog = false)
      );
    } else if (this.CURRENT_EVENT_OBJECT.status !== 'Translation Pending') {
      this.showWarningDialog = true;
    }
  },
  async created() {
    await this.fetchTranslations(this.$route.params.id);
    try {
      if (
        this.USER_GROUPS.indexOf('SLTranslator') === -1 &&
        this.USER_GROUPS.indexOf('Admin') === -1
      ) {
        this.$router.push('/dashboard');
      }
      this.formattedTimezone =
        OptionData.humanReadableTimeZoneFromValue(
          this.CURRENT_EVENT_OBJECT.timezone
        ) + ' Time';
      this.bitlyLink = await this.$store.dispatch(
        'EVENT_MANAGER_STORE/FETCH_BITLY_LINK',
        this.$route.params.id
      );
      if (this.CURRENT_EVENT_OBJECT.fullPrizeDescription == null) {
        this.formattedPrizeDescription = 'No Prize.';
      } else {
        this.formattedPrizeDescription = this.CURRENT_EVENT_OBJECT.fullPrizeDescription
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#039;')
          .replace(/\n/g, '<br />')
          .trim();
      }
    } catch (e) {}
  },
  watch: {
    brandWatcher: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.changeTheme(newVal);
        }
      }
    }
  },
  data() {
    return {
      bitlyLink: '',
      attemptPublish: false,
      patchCallOut: false,
      dialog: false,
      dialogTitle: '',
      dialogMessage: '',
      dialogActionText: '',
      dialogAction: null,
      valid: false,
      showDialog: false,
      showWarningDialog: false,
      showApproveDialog: false,
      originalEvent: {},
      validationAttempted: false,
      submitLoading: false,
      loading: false,
      formattedTimezone: null,
      translationDialog: false,
      contractDialog: false,
      formattedPrizeDescription: null,
      nonEmptyRule(inputError) {
        return v => v === 0 || v === false || !!v || inputError;
      },
      checkboxGroupRule(checkboxGroupObj, inputError) {
        return () =>
          Object.values(checkboxGroupObj).some(k => k === true) || inputError;
      }
    };
  },
  updated() {
    // log the event before the user makes changes to state
    if (
      Object.keys(this.originalEvent).length === 0 &&
      this.CURRENT_EVENT_OBJECT.engagements !== undefined
    ) {
      this.originalEvent = JSON.stringify(this.CURRENT_EVENT_OBJECT);
    }
  },

  methods: {
    ...mapActions({
      sendEmail: 'admin/sendEmail',
      approveTranslations: 'approveTranslations',
      fetchTranslations: 'fetchTranslations'
    }),
    getEventStatus() {
      if (this.CURRENT_EVENT_OBJECT.status) {
        return this.CURRENT_EVENT_OBJECT.status;
      } else {
        return this.CURRENT_EVENT_OBJECT.published == 1 ? 'Published' : 'Draft';
      }
    },
    changeTheme(brand) {
      ThemeSwitcher.ChangeTheme(this, brand);
    },

    UserEmailLegalSent: function(toAddress, eventName) {
      let eventStartDate = `${this.CURRENT_EVENT_OBJECT.startDate} ${this.CURRENT_EVENT_OBJECT.startTime}`;
      let eventEndDate = `${this.CURRENT_EVENT_OBJECT.endDate} ${this.CURRENT_EVENT_OBJECT.endTime}`;
      let given_name = this.CURRENT_EVENT_OBJECT.createdByFirstName;
      let family_name = this.CURRENT_EVENT_OBJECT.createdByLastName;
      let email = this.CURRENT_EVENT_OBJECT.createdByEmail;
      let legalDetails = this.CURRENT_EVENT_OBJECT.legalDetails;
      let contract = this.CURRENT_EVENT_OBJECT.legalContract
        ? this.CURRENT_EVENT_OBJECT.legalContract
        : 'N/A';

      let templateData = {
        preheader_text: '',
        header_text: 'T-Mobile Engagement Hub Event Translation',
        greeting_text: `Hi ${given_name},`,
        'body-1_text': 'Your event has been translated and published.',
        'body-2_text':
          'Event Name: ' +
          eventName +
          '<br>' +
          'Event Start Date: ' +
          eventStartDate +
          '<br>' +
          'Event End Date: ' +
          eventEndDate +
          '<br>' +
          'Event Created by: ' +
          given_name +
          ' ' +
          family_name +
          '; ' +
          email +
          '<br>' +
          'Legal Details: ' +
          legalDetails +
          '<br>' +
          'Contract, if provided: ' +
          contract,
        subject_text:
          'Translation Approved for Event ID: ' + this.CURRENT_EVENT_OBJECT.id,
        'text-part_text': '',
        'cta-1_href': `https://www.t-mobileengagementhub.com/#/event/edit/${this.CURRENT_EVENT_OBJECT.id}`,
        'cta-1_text': 'View'
      };
      let emailTemplate = {
        Source:
          'Translation Review Receipt <donotreply@t-mobileengagementhub.com>',
        Template: 'one-cta-template',
        ConfigurationSetName: 'ConfigSet',
        Destination: {
          ToAddresses: [`${toAddress}`]
        },
        TemplateData: JSON.stringify(templateData, null, 2)
      };
      return emailTemplate;
    },
    async approveTranslationAction() {
      try {
        if (this.CURRENT_EVENT_OBJECT.status !== 'Translation Pending') {
          this.showWarningDialog = true;
          return;
        }

        if (
          !this.translations.event_name ||
          !this.translations.abbreviatedPrizeDescription ||
          (this.showSweepstakesTitle && this.CURRENT_EVENT_OBJECT.contestType ===
            'Enter for a Chance to Win' &&
            this.CURRENT_EVENT_OBJECT.sweepstakesTitle &&
            !this.translations.sweepstakesTitle) ||
          (this.showFullPrizeDescription && this.CURRENT_EVENT_OBJECT.contestType ===
            'Enter for a Chance to Win' &&
            this.CURRENT_EVENT_OBJECT.fullPrizeDescription &&
            !this.translations.fullPrizeDescription) ||
          (this.CURRENT_EVENT_OBJECT.abbreviatedLegal &&
            !this.translations.abbreviatedLegal)
        ) {
          this.dialogTitle = 'Empty Fields';
          this.dialogMessage =
            'One or more of your fields are empty. Please fill these fields before proceeding.';
          this.dialogActionText = 'OK';
          this.dialogAction = () => {
            this.dialog = false;
          };
          this.dialog = true;
          return;
        }
        this.submitLoading = true;
        let response = await this.approveTranslations(
          this.CURRENT_EVENT_OBJECT.id
        );
        response = JSON.parse(response);
        if (response.errorMessage !== undefined) {
          this.displayDialog(
            'Error',
            response.errorMessage,
            'Close',
            () => (this.dialog = false)
          );
          return;
        }
        this.submitLoading = false;
        // show dialog
        this.showApproveDialog = true;
        // send translation approved email
        let email = this.CURRENT_EVENT_OBJECT.createdByEmail;

        this.sendEmail(
          this.UserEmailLegalSent(email, this.CURRENT_EVENT_OBJECT.name)
        );

        this.submitLoading = false;
        // show dialog
        this.showApproveDialog = true;
      } catch (error) {
        this.dialogTitle = 'Error';
        this.dialogMessage =
          error ||
          'We are experiencing unusually high traffic at this time, please try to save your event again or contact us if this continues occuring.';
        this.dialogActionText = 'Close';
        this.dialogAction = () => {
          this.dialog = false;
        };
        return (this.dialog = true);
      }
    },
    displayDialog(title, message, actionText, action) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogActionText = actionText;
      this.dialogAction = action;
      this.dialog = true;
    }
  },
  computed: {
    optionData: function() {
      return OptionData;
    },
    helperFunctions: function() {
      return HelperFunctions;
    },
    urlRoot: function() {
      return window.location.origin;
    },
    multipleEntryPeriodsCheck() {
      return (
        this.CURRENT_EVENT_OBJECT['intervals'] &&
        Array.isArray(this.CURRENT_EVENT_OBJECT['intervals']) &&
        this.CURRENT_EVENT_OBJECT['intervals'].length > 1
      );
    },
    ...mapGetters({
      userAttributes: `account/USER_ATTRIBUTES`,
      USER_GROUPS: `account/USER_GROUPS`,
      translations: 'translations'
    }),
    ...mapState('EVENT_MANAGER_STORE', ['CURRENT_EVENT_OBJECT']),
    brandWatcher() {
      return this.CURRENT_EVENT_OBJECT && this.CURRENT_EVENT_OBJECT.brand
        ? this.CURRENT_EVENT_OBJECT.brand
        : 'T-Mobile';
    },
    showFullPrizeDescription() {
      return this.CURRENT_EVENT_OBJECT.contestType ===
        'Enter for a Chance to Win' &&
        !this.CURRENT_EVENT_OBJECT.abbreviatedLegal
    },
    showSweepstakesTitle() {
      return this.CURRENT_EVENT_OBJECT.contestType ===
        'Enter for a Chance to Win' &&
        !this.CURRENT_EVENT_OBJECT.abbreviatedLegal
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .event-brand .v-input--selection-controls__input {
  display: none;
}

::v-deep .v-input--checkbox {
  margin-top: 0;
}

::v-deep .v-input--radio-group {
  margin-top: 0;
  padding-top: 0;
}

.fixed-buttons {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #9b9b9b;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 1;
}

.exit-button {
  background-color: white;
}

.button-text {
  font-weight: bold;
}

::v-deep .v-btn--outlined {
  border-width: 2px !important;
}

.wide-container {
  // max-width: 1442px;
  margin: auto;
}

@media (max-width: 1470px) {
  // .wide-container {
  //   // width: 90%;
  // }
}

.event-link {
  font-weight: bold;
}

.bitly-skeleton {
  display: inline-block;
  width: 100px;
  margin-top: 5px;
}
.entry-period {
  padding: 0;
}
.gray-divider-wrapper {
  width: 100%;
  padding: 0 13.5px;
}
.cta-buttons {
  padding-bottom: 50px;
}

.embedded-rules {
  width: 100%;
  height: 100vh;
  display: block;
}

.translation-link {
  color: var(--v-primary-base);
}
.v-text-field {
  margin-top: -4px;
  padding-top: 0;
}
::v-deep .v-text-field__slot {
  width: 100%;
}

.v-textarea.v-text-field {
  margin-top: 0;
}

.event-info p {
  align-self: stretch;
  flex: 1 1 auto;
  line-height: 1.75rem;
  max-width: 100%;
  min-height: 32px;
  outline: none;
  padding: 0;
  width: 100%;
}
</style>
