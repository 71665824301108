var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"form",staticClass:"event-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"grey"},[_c('div',{staticClass:"wide-container"},[_c('TranslationFormSection',{attrs:{"card":false,"wide":"","compact":""}},[_c('div',{attrs:{"slot":"section-content"},slot:"section-content"},[_c('v-container',[_c('TranslationFormSection',{attrs:{"card":false,"compact":""}},[_c('div',{attrs:{"slot":"section-content"},slot:"section-content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"event-link"},[_vm._v("Event ID: ")]),_vm._v(" "+_vm._s(_vm.CURRENT_EVENT_OBJECT.id)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"event-link"},[_vm._v("Event Status: ")]),_vm._v(" "+_vm._s(_vm.CURRENT_EVENT_OBJECT.archived ? 'Archived' : _vm.CURRENT_EVENT_OBJECT.status)+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"event-link"},[_vm._v("Public Event URL: ")]),(!_vm.bitlyLink)?_c('v-skeleton-loader',{staticClass:"bitly-skeleton",attrs:{"max-width":"300","type":"text"}}):_vm._e(),_c('a',{attrs:{"href":_vm.bitlyLink,"target":"_blank"}},[_vm._v(_vm._s(_vm.bitlyLink))])],1),(
                        _vm.CURRENT_EVENT_OBJECT.contestType ===
                          'Enter for a Chance to Win'
                      )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"event-link"},[_vm._v(" Public Rules URL: ")]),_c('router-link',{attrs:{"to":'/event/rules/' + _vm.$route.params.id,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.urlRoot + '/#/event/rules/' + _vm.CURRENT_EVENT_OBJECT.id)+" ")])],1):_vm._e()],1)],1)])],1)],1)])],1)]),_c('div',{staticClass:"wide-container"},[_c('TranslationFormSection',{attrs:{"card":false}},[_c('div',{attrs:{"slot":"section-content"},slot:"section-content"},[_c('v-container',[_c('TranslationFormSection',{attrs:{"card":false,"compact":""}},[_c('span',{attrs:{"slot":"section-header"},slot:"section-header"},[_vm._v("Translation Fields")]),_c('div',{attrs:{"slot":"section-content"},slot:"section-content"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"event-link"},[_vm._v(" Event Name: ")])]),_c('v-col',{staticClass:"event-info",attrs:{"cols":"12","md":"6"}},[_vm._v(" "+_vm._s(_vm.CURRENT_EVENT_OBJECT.name)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:"name",attrs:{"rules":[
                          _vm.nonEmptyRule(
                            'You must supply a translation for Event Name'
                          )
                        ],"hide-details":"auto","append-icon":"mdi-alert-circle","required":"","readonly":!!_vm.CURRENT_EVENT_OBJECT.archived},model:{value:(_vm.translations.event_name),callback:function ($$v) {_vm.$set(_vm.translations, "event_name", $$v)},expression:"translations.event_name"}})],1)],1),(_vm.showSweepstakesTitle)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"event-link"},[_vm._v(" Sweepstakes Title: ")])]),_c('v-col',{staticClass:"event-info",attrs:{"cols":"12","md":"6"}},[_c('p',[_vm._v(_vm._s(_vm.CURRENT_EVENT_OBJECT.sweepstakesTitle))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:"sweepstakes",attrs:{"rules":[
                          _vm.nonEmptyRule(
                            'You must supply a translation for Sweepstakes Title.'
                          )
                        ],"hide-details":"auto","append-icon":"mdi-alert-circle","required":"","readonly":!!_vm.CURRENT_EVENT_OBJECT.archived},model:{value:(_vm.translations.sweepstakesTitle),callback:function ($$v) {_vm.$set(_vm.translations, "sweepstakesTitle", $$v)},expression:"translations.sweepstakesTitle"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"event-link"},[_vm._v(" Abbreviated Prize Description: ")])]),_c('v-col',{staticClass:"event-info",attrs:{"cols":"12","md":"6"}},[_c('p',[_vm._v(" "+_vm._s(_vm.CURRENT_EVENT_OBJECT.abbreviatedPrizeDescription)+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{ref:"abbreviatedPrizeDescription",attrs:{"rules":[
                          _vm.nonEmptyRule(
                            'You must provide an abbreviated prize description.'
                          ),
                          function (v) { return v == null ||
                            v.length <= 100 ||
                            'Abbreviated prize description cannot be greater than 100 characters.'; }
                        ],"rows":"1","required":"","auto-grow":"","hide-details":"auto","append-icon":"mdi-alert-circle","readonly":!!_vm.CURRENT_EVENT_OBJECT.archived,"counter":"100"},model:{value:(_vm.translations.abbreviatedPrizeDescription),callback:function ($$v) {_vm.$set(_vm.translations, "abbreviatedPrizeDescription", $$v)},expression:"translations.abbreviatedPrizeDescription"}})],1)],1),(_vm.showFullPrizeDescription)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"event-link"},[_vm._v(" Full Prize Description: ")])]),_c('v-col',{staticClass:"event-info",attrs:{"cols":"12","md":"6 "}},[_c('p',{staticClass:"ma-0",domProps:{"innerHTML":_vm._s(_vm.formattedPrizeDescription)}})]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{ref:"fullPrizeDescription",attrs:{"rules":[
                          _vm.nonEmptyRule(
                            'You must provide a prize description.'
                          ),
                          function (v) { return v == null ||
                            v.length <= 500 ||
                            'Prize description cannot be greater than 500 characters.'; }
                        ],"rows":"1","auto-grow":"","hide-details":"auto","append-icon":"mdi-alert-circle","readonly":!!_vm.CURRENT_EVENT_OBJECT.archived,"counter":"500","required":""},model:{value:(_vm.translations.fullPrizeDescription),callback:function ($$v) {_vm.$set(_vm.translations, "fullPrizeDescription", $$v)},expression:"translations.fullPrizeDescription"}})],1)],1):_vm._e(),(_vm.CURRENT_EVENT_OBJECT.abbreviatedLegal)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"event-link"},[_vm._v(" Abbreviated Legal ")])]),_c('v-col',{staticClass:"event-info",attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"ma-0",domProps:{"innerHTML":_vm._s(_vm.CURRENT_EVENT_OBJECT.abbreviatedLegal)}})]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{ref:"abbreviatedLegal",attrs:{"rules":[
                          _vm.nonEmptyRule(
                            'You must supply a translation for Abbreviated Legal.'
                          )
                        ],"hide-details":"auto","rows":"1","append-icon":"mdi-alert-circle","required":"","auto-grow":"","readonly":!!_vm.CURRENT_EVENT_OBJECT.archived},model:{value:(_vm.translations.abbreviatedLegal),callback:function ($$v) {_vm.$set(_vm.translations, "abbreviatedLegal", $$v)},expression:"translations.abbreviatedLegal"}})],1)],1):_vm._e()],1)],1)])],1)],1)]),_c('v-container',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.CURRENT_EVENT_OBJECT.status === 'Translation Pending'),expression:"CURRENT_EVENT_OBJECT.status === 'Translation Pending'"}],staticClass:"justify-center cta-buttons"},[_c('v-col',{attrs:{"cols":"10","md":"3","lg":"2"}},[_c('v-btn',{attrs:{"elevation":"2","tile":"","block":"","x-large":"","loading":_vm.submitLoading,"disabled":_vm.CURRENT_EVENT_OBJECT.status !== 'Translation Pending' ||
                  this.USER_GROUPS.indexOf('SLTranslator') === -1,"color":"primary"},on:{"click":function($event){return _vm.approveTranslationAction()}}},[_c('span',{staticClass:"white--text button-text"},[_vm._v(" Approve Translation ")])])],1)],1)],1)],1)]),_c('v-dialog',{attrs:{"width":"500","content-class":"app-modal-dialog"},model:{value:(_vm.showWarningDialog),callback:function ($$v) {_vm.showWarningDialog=$$v},expression:"showWarningDialog"}},[_c('v-card',{staticClass:"pa-8"},[_c('v-card-title',{staticClass:"justify-center grey lighten-2"},[_vm._v(" Event Status Changed ")]),_c('v-card-text',{staticClass:"mt-2 text-center"},[_vm._v(" This event's status has changed to "+_vm._s(_vm.CURRENT_EVENT_OBJECT.status)+". An event can only be Approved when it is in Translation Pending status. ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center pt-8"},[_c('v-btn',{staticClass:"px-6 modal-button-outlined",attrs:{"color":"primary","outlined":"","tile":"","x-large":""},on:{"click":function($event){_vm.showWarningDialog = false}}},[_c('span',{staticClass:"black--text button-text"},[_vm._v(" Close ")])])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500","content-class":"app-modal-dialog"},model:{value:(_vm.showApproveDialog),callback:function ($$v) {_vm.showApproveDialog=$$v},expression:"showApproveDialog"}},[_c('v-card',{staticClass:"pa-8"},[_c('v-card-title',{staticClass:"justify-center grey lighten-2"},[_vm._v(" EVENT TRANSLATED ")]),_c('v-card-text',{staticClass:"mt-2 text-center"},[_vm._v(" Thank you! The Event Manager will be notified of their event status change. ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center pt-8"},[_c('v-btn',{staticClass:"px-6 modal-button-outlined",attrs:{"color":"primary","outlined":"","tile":"","x-large":""},on:{"click":function($event){_vm.USER_GROUPS.indexOf('SLTranslator') !== -1
              ? _vm.$router.push('/translationdashboard')
              : _vm.$router.push('/translationdashboard')}}},[_c('span',{staticClass:"black--text button-text"},[_vm._v(" Close ")])])],1)],1)],1),_c('v-dialog',{attrs:{"width":"600","content-class":"app-modal-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-8"},[_c('v-card-title',{staticClass:"justify-center grey lighten-2"},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c('v-card-text',{staticClass:"mt-2 text-center"},[_vm._v(" "+_vm._s(_vm.dialogMessage)+" ")]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-center pt-8"},[_c('v-btn',{staticClass:"px-6 btn__primary",attrs:{"text":""},on:{"click":_vm.dialogAction}},[_vm._v(" "+_vm._s(_vm.dialogActionText)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }